<template>
    <div>
        <b-container class="mt-5 mb-5">
            <h1 class="fs-4 font-weight-bold mb-4">Alle klanten</h1>
            <b-alert variant="info" show>
                <font-awesome-icon icon="fa-solid fa-info-circle" class="mr-1" />
                Hier vind je al je klanten terug die een bestelling hebben geplaatst bij één van je evenementen.
            </b-alert>
            <b-card no-body>
                <b-card-header>
                    <div class="float-sm-left">
                        Aantal gevonden: {{ customers.length }}
                    </div>
                    <div class="float-sm-right">
                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <font-awesome-icon icon='fa-solid fa-search' />
                            </b-input-group-prepend>
                            <b-form-select v-model="searchSelected" :options="searchOptions" @change="filterCustomers($event)" />
                        </b-input-group>
                    </div>
                </b-card-header>
                <b-card-body>
                    <div v-if="customers.length <= 0" class="text-center text-muted mt-4 mb-4">
                        <font-awesome-icon icon="fa-solid fa-users" class="mb-2" size="2x" />
                        <div class="text-dark fs-5">Geen klanten gevonden</div>
                    </div>
                    <template v-else>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <td>Voornaam</td>
                                    <td>Achternaam</td>
                                    <td>E-mail</td>
                                    <td>Evenement</td>
                                    <td>Datum</td>
                                    <td>Ingeschreven op</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(customer,key) in customers" :key="key">
                                    <td>{{ customer.firstname }}</td>
                                    <td>{{ customer.lastname }}</td>
                                    <td>{{ customer.email }}</td>
                                    <td>{{ customer.event.name }}</td>
                                    <td>{{ customer.event.date.start_at|formatDateTime }}</td>
                                    <td>{{ customer.created_at.date|formatDateTime }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </b-card-body>
                <b-card-footer>
                    Aantal gevonden: {{ customers.length }}
                </b-card-footer>
            </b-card>
        </b-container>
    </div>
</template>
  
<script>
    export default {
        metaInfo: {
            title: 'Alle klanten',
        },
        components: {
        },
        data() {
            return {
                searchSelected: null,
                customers: [],
                events: [],
            }
        },
        computed: {
            searchOptions() {
                let array = [];
                array.push({ text: 'Selecteer een event', value: null});

                this.events.forEach(item => {
                    array.push({ text: item.name, value: item.id })
                });

                return array;
            }
        },
        methods: {
            getCustomers: function() {
                this.$axios.get('https://api.tixgo.nl/customers')
                .then( response => {
                    if (response.data) {
                        this.customers = response.data;
                    }
                }
                ).catch((reason) => {
                    console.log(reason);
                });
            },
            getEvents: function() {
                this.$axios.get('https://api.tixgo.nl/events')
                .then( response => {
                    if (response.data) {
                        this.events = response.data;
                    }
                }
                ).catch((reason) => {
                    console.log(reason);
                });
            },
            filterCustomers: function(event_id) {

                if (!event_id) {
                    return this.getCustomers();
                }
                
                this.$axios.get('https://api.tixgo.nl/customers?event=' + event_id)
                .then( response => {
                    if (response.data) {
                        this.customers = response.data;
                    }
                }
                ).catch((reason) => {
                    console.log(reason);
                });
            }
        },
        created() {
            this.getEvents();
            this.getCustomers();
        }
    }
</script>